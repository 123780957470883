import { template as template_22d8931c2fbb492c8ea25dc7bf69960c } from "@ember/template-compiler";
const FKLabel = template_22d8931c2fbb492c8ea25dc7bf69960c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
