import { template as template_c487bd1b7b274161befaa60e5d3100b7 } from "@ember/template-compiler";
const FKText = template_c487bd1b7b274161befaa60e5d3100b7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
